<template>
  <section class="wa-full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb2rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title"> Welkom bij {{ projectTitle }} - SuperAdmin</div>
      </div>
    </div>

  </section>
</template>

<script>

export default {
  name: "SuperAdminPage",  
  data() {
    return {
      projectTitle : process.env.VUE_APP_TITLE
    }
  }
};
</script>

<style scoped>
</style>